.footer-wrapper {
  
  background-color: $blackBackground;

  .contacts__phone {
    font-size: 13px;
    display: flex;
    flex-direction: column;
  }

  .contacts__mail {
    font-size: 13px;
  }
}

.footer-logo {
  height: 38px;
  width: 160px;
  margin-left: -40px;
  object-fit: contain;
}



.footer-line {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    opacity: .4;
  }
}

.footer-copyright {
  color: #fff;
  font-family: 'Muller';
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  p {
    color: #fff;
    font-weight: 300;
    letter-spacing: .25px;
  }

  .logoReshka {
    display: block;
    text-align: center;
    padding: 30px 0;
  }
}

