.car-covers__features {
  display: flex;

  &-item {
    display: flex;

    img {
      max-width: 42px;
      display: block;
      max-height: none;
    }
  }

  &__text {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 18px;
    font-family: 'Muller';
    margin-left: 30px;
  }
}