.popup-wrapper {
  position: fixed;
  width: 100%;
  top: 40vh;
 
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: -5;
  transition: .3s ease-in-out;

  &.active {
    opacity: 1;
    z-index: 15;
  }

  @include w768 {
    right: -50%;
  }
}

.popup-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  height: 150px;
}

.region-popup__link {
  text-align: center;
  width: 190px;
  height: 50px;
  padding: 19px 20px;
  min-width: 145px;

  .arrowLink {
    padding: 0 5px;
    margin-bottom: 3px;
  }
}

.popup__close-popup {
  position: absolute;
  top: 0;
  right: 0;
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 15px;
  font-weight: 100;
  background: none;
  border: none;
  color: rgba(255, 255, 255, .6);

  &:before {
    content: "\e912";
  }
}