.restoration-services__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  &-item {
    display: flex;
    align-items: center;
    box-shadow: 3px 4px 15px rgba(51,51,51,.1);
    border-radius: 2px;
    padding: 10px 50px;

    i {
      font-size: 46px;
      margin-left: -70px;
    }

    img {
      max-width: 49px;
      height: 50px;
    }

    p {
      color: $blackBackground;
      font-family: 'Muller';
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: .7px;
      margin-left: 30px;
    }
  }
}