.model-raw__wrapper {
  background-color: $blackBackground;
  margin-top: 50px;
  margin-bottom: 0;
  padding: 30px 0;
}

.model-raw__container {
  display: flex;
  flex-wrap: wrap;

  .page-title {
    order: 1;
  }

  .button {
    order: 3;
    display: block;
    padding: 15px 30px;
    margin: 0 auto;
  }

}

.swiper-container {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 80px 0 80px;
  order: 2;

  .swiper-button {
    background-color: rgba(0,0,0,.6);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
    &-prev, &-next {
      color: #fff;
      

      &::after {
        content: '';
      }
    }
  }
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  padding-bottom: 20px;
  background-color: #fff;
  text-align: center;
  filter: blur(3px);
  transition: $transition;
  max-height: 400px;

  a {
    opacity: 0;
    transition: .3s opacity ease-in-out .3s;
  }

  &-active {
    filter: blur(0);

    a {
      opacity: 1;
    }
  }

  .slide-title {
    color: $blackBackground;
    font-family: 'Muller';
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: .8px;
    position: relative;
    padding-top: 20px;

    &::before {
      content: '';
      width: 90%;
      position: absolute;
      top: 0;
      left: 5%;
      border-bottom: 1px solid #efefef;
    }
  }

  

  a {
    color: $globalColorLink;
    position: relative;
    

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 92%;
      background-color: $globalColorLink;
      transition: $transition;
    }

    .icon-link {
      font-size: 9px;
    }

    @include hover {
      &::after {
        left: 92%;
        width: 0;
      }
    }
  }
}