.button {
  display: -webkit-box; 
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Muller' !important;
  text-transform: uppercase;
  border-radius: 2px;
  letter-spacing: .6px;
  border: none;
  max-width: 250px;
  text-align: center;
  transition: $transition;

  &__green {
    background-color: $globalColorLink;
    color: white;
  }

  &__transparent {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
  }

  &__medium {
    min-width: 145px;
    max-width: 215px;
    height: 50px;
    font-size: 12px;
  }

  &__small {
    max-width: 105px;
    height: 30px;
  }

  .icon-link {
    margin-left: 5px;
  }

  @include hover {
    filter: brightness(1.2);
  }
}

.dotted {
  border-bottom: 1px dotted #fff;
  transition: $transition;

  @include hover {
    border-color: transparent;
  }
}