.partners__wrapper {
  background-color: #f6f7f8;
  padding: 60px 16px;

  .partners__container {
    @include mobileContainer;
  }

  p {
    
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    font-family: 'Muller';
    letter-spacing: .38px;
    margin-bottom: 70px;
  }
}

.partners__slider {
  img {
    object-fit: contain;
    height: 92px;
  }

  .slick-arrow {
    background-color: black;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: -40px;
    font-family: 'Font Awesome 5 Free';

    &.slick-prev {
      left: 30%;

      &:before {
        content: '\f104';
      }
    }

    &.slick-next {
      right: 30%;

      &:before {
        content: '\f105';
      }
    }

    @include w768 {
      &.slick-prev {
        left: 40%;
      }
  
      &.slick-next {
        right: 40%;
      }
    }

    &.slick-next, &.slick-prev {
      &:before {
        font-family: 'Font Awesome 5 Free';
        font-size: 16px;
        opacity: 1;
        transition: .3s ease-in-out;
      }
      &:hover, &:focus {
        background-color: $globalColorLink;
      }
    }
  }
}