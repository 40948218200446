.form-popup__wraper {
  position: fixed;
  top: 9vh;
  left: 0%;
  height: 85vh;
  width: 100%;
  max-width: $mobileMaxWidth;
  display: none;

  &.active {
    display: block;
    z-index: 11;
  }
}

.form__container {
  background-color: $blackBackground;
  display: flex;
  flex-direction: column;
  position: relative;
  height: inherit;
  width: 98vw;
  max-width: $mobileMaxWidth;
  margin: auto;
  padding: 40px 30px ;
  
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    max-width: 20px;
    max-height: 20px;
    overflow: hidden;
    border-radius: 100px;
    background-color: $globalColorLink;
    line-height: 20px;
    text-align: center;
    border: none;
    display: block;
    -webkit-appearance: none;

    &:before {
      content: "\f00d";
      font-family: 'Font Awesome 5 Free';
      color: #fff;
      position: absolute;
      z-index: 12;
      top: 0;
      right: 5px;
    }
  }

  .form-title {
    color: $globalColorLink;
    font-family: 'Muller';
    font-size: 22px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-weight: 300;
    margin-bottom: 10px;
  }

  .subtitle {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    color: #fff;
    font-size: 16px;
    letter-spacing: .25px;
    line-height: 22px;
    margin: 0 auto;
    text-align: center;
    font-weight: 300;
  }

  .form {
    margin-top: 46px;
    display: flex;
    flex-direction: column;

    .form__inputs-block {
      display: flex;
      flex-direction: column;
    }

    .input-label {
      color: white;
    }

    input[type="submit"] {
      display: block;
      width: auto;
      margin: 30px auto 0;
      padding: 0;
    }
  }

  input, textarea {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 20px;
    padding: 16.5px 18px;
    font-size: 14px;
    font-weight: 300;
    font-family: inherit;
    border: none;
    border-radius: 2px;
  }

  .form__checkbox {
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: .1px;
    font-family: 'Muller';
    color: #7d868c;
    font-weight: 300;

    label {
      padding-left: 30px;
    }

    label:before {
      content: '';
      position: absolute;
      top: -5px;
      left: 0;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      border: 1px solid #dfe1e2;
      background-color: #fff;
      text-align: center;
      line-height: 18px;
    }

    [type=checkbox]:checked+label:before {
      content: "\f00c";
      font-family: 'Font Awesome 5 Free';
      font-size: 10px;
      color: #7d868c;
  }
    
    input {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}