.spares__card-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;

    &__icon-category {
      position: absolute;
      background-color: #fff;
      box-shadow: 10px 0 30px #dee4e9;
      z-index: -1;
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      text-align: end;
      transition: $transition;

      img {
        object-fit: contain;
        height: 100%;
      }
    }

    &__category-name {
      width: 100%;
      color: $blackBackground;
      font-size: 10.5px;
      line-height: 18px;
      text-transform: uppercase;
      font-family: 'Muller';
      letter-spacing: .35px;
      transition: $transition;
    }

    @include hover {
      .spares__card-list__item__icon-category {
        background-color: $blackBackground;
        img {
          opacity: .5;
        }
      }
      

      .spares__card-list__item__category-name {
        color: #fff;
      }
    }

  }

  @include w600 {

    &__item {
      padding: 24px 36px 22px;

      &__category-name {
        font-size: 14px;
        line-height: 23px;
        font-weight: 300;
      }
    }
  }
}