.burger__menu {
  position: relative;
  background-color:  transparent;
  border: none;
  width: 30px;
  height: 25px;

  &.active {
    .burger__lines {
      top: 10px;
      transform: rotate(45deg);
      &:before {
        top: 0px;
        transform: rotate(-90deg);
      }

      &:after {
        opacity: 0;
      }
    }
  }

  .burger__lines {
    width: 30px;
    height: 4px;
    background-color: $globalColorLink;
    border-radius: 20px;
    position: absolute;
    top: 0;
    right: 0;
    transition: .3s ease-in-out;

    &:before, &:after {
      transition: .3s ease-in-out;
      content: '';
      width: 30px;
      height: 4px;
      border-radius: 20px;
      background-color: $globalColorLink;
      position: absolute;
      right: 0;
    }

    &:before {
      top: 10px;
    }

    &:after {
      top: 20px;
      width: 23px;
    }
  }
}