.slider_wrapper {
  position: relative;
  overflow-x: hidden;

  .pagingInfo {
    position: absolute;
    z-index: 1;
    color: #fff;
    bottom: 35px;
    left: 49%;
  }
}

.slick-slide {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(43,46,55, .7);
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 16px;
    font-family: 'Muller';
    letter-spacing: .5px;
    text-transform: uppercase;

    @include w600 {
      padding: 0 90px;
    }
  }

  .slider__title {
    width: 100%;
    font-size: 30px;
    font-weight: 800;
    line-height: 1;
    margin-bottom: 5px;

    @include w600 {
      font-size: 43px;
    }
  }

  .slider__text {
    font-size: 13px;

    @include w600 {
      font-size: 24px;
      letter-spacing: .5px;
    }
  }
}