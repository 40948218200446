.breadcrumbs {
  background-color: $blackBackground;
  padding: 15px 0;
  border-top: 1px  solid #3d4048;

  &__container {
    @include desktopContainer;
  }

  &__link {
    color: #ddd;
    font-size: 12px;
    font-family: 'Muller';
    font-weight: 300;
    letter-spacing: .6px;
    border-bottom: 1px solid #09783d;
    padding-bottom: 5px;
    position: relative;
    margin-left: 10px;
    transition: $transition;

    &.active {
      color: #fff;
      border:none;
    }
    
    @include hover {
      border-color: transparent;
    }

    &:after {
      content: ' -';
      position: absolute;
      top: 0%;
      right: -10px;
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}