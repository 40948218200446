.page-spare__category-list {
  display: flex;
  flex-wrap: wrap;
  margin: 30px 0;
  
  &__item {
    background-color: #fff;
    margin-bottom: 2%;
    text-align: center;
    position: relative;

    img {
      height: 180px;
      object-fit: cover;
      margin: 20px 0;
    }

    .page-spare__category-name {
      color: $blackBackground;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      text-transform: uppercase;
      letter-spacing: .8px;
      padding: 20px 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        height: 1px;
        background-color: #efefef;
        border-radius: 2px;
      }
    }

    .page-spare__category-list__item-overlay {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.2);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: -2;
      opacity: 0;
      transition: .5s opacity ease-in-out;

      .button {
        padding: 0 25px;
      }
    }

    @include hover {
      .page-spare__category-list__item-overlay {
        z-index: 1;
        opacity: 1;
      }
    }
  }
}

.page-spare__features {
  background-color: $blackBackground;
  padding: 50px 0;

  &-list {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    &__item {
      background-color: #fff;
      margin-bottom: 30px;
      display: flex;
      border-radius: 2px;

      p {
        color: $blackBackground;
        font-family: 'Muller';
        font-size: 14px;
        line-height: 26px;
        letter-spacing: .7px;
        padding: 0 15px;
      }

      i {
        font-size: 54px;
      }
    }
  }
}