.contacts__icon {
  position: relative;

  &:before {
    font-family: 'Font Awesome 5 Free';
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: #09783d;
    color: #fff;
    font-size: 10px;
    line-height: 22px;
    text-align: center;
    position: absolute;
    left: -32px;
  }
}

.contacts__phone {
  &:before {
    content: "\f095";
    top: 3px;
    transform: rotate(90deg);
  }
}

.contacts__mail {
  &:before {
    content: "\f0e0";
    top: -2px;
    transform: rotate(0);
  }
}

.contacts__address {
  &:before {
    color: white;
    content: "\f041";
    top: 20%;
  }
}

.contacts__social {
  padding: 16.1px 27px 0;
  font-family: 'Font Awesome 5 Brands';
  display: flex;
  justify-content: center;
  .social__link {
    display: block;
    position: relative;
    margin: 0 8px;
    color: white;
    font-size: 16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    &:before {
      position: absolute;
      top: 50%; 
      left: 50%;
      transform: translate(-50%, -50%);
    }
    
    &.vk{
      background-color: #6180b5;
      &:before{
        content: "\f189";
      }
    }

    &.instagram {
      background-color: #9d3689;
      &:before {
        content: "\f16d";
      }
    }

    &.fb {
      background-color: #4f67af;
      &:before {
        content: "\f39e";
      }
    }
  }
}

.arrow-icon {
  position: relative;
  width: 5px;
  height: 5px;

  &::after, &::before {
    content: '';
    position: absolute;
    right: -10px;
    top: 40%;
    background-color: $globalColorLink;
    width: 5px;
    height: 2px;
  }

  &::after {
    transform: rotate(45deg);
    right: -8px;
  }

  &::before {
    transform: rotate(-45deg);
  }

  &.active {
    &::after {
      transform: rotate(-45deg);
    }
  
    &::before {
      transform: rotate(45deg);
    }
  }
}