.promotion__wrapper {
  
  .promotion__container {
    background-color: #262a35;
    background-image: url(../img/backgrounds/sale-bg.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    padding: 30px 16px;
  }
  
  .button {
    max-width: 145px;

    @include hover {
      background-color: #fff;
      color: $globalColorLink;
      filter: none;
    }
  }
}

.promotion-list {
  margin: 30px 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    margin-bottom: 10px;

    &-img {

      img {
        width: 130px;
        height: 110px;
        object-fit: cover;
        margin-left: -16px;
      }
    }

    &-description {
      color: $blackBackground;
      font-weight: 300;
        font-size: 16px;
        line-height: 22px;

      &__title {
        color: #7d868c;
        font-size: 20px;
        font-weight: 300;
      }

      &__text {
        font-weight: 300;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}