// this file contain style for promotionCard.html

.promotion-card__card-banner {
  margin-top: 30px;
}

.promotion-card__full-description {
  a {
    color: $globalColorLink;
    text-decoration: underline;
  }
}

.page-wrapper {
  .page-container {
    .promotion-list {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
    }
  }
}

.promotion__wrapper{
  .promotion-list {
    justify-content: space-between;
  }
}

.promotion-card {
  position: relative;

  &__img {
    overflow: hidden;
    img {
      object-fit: cover;
      height: 200px;
    }
  }

  &__description {
    border-left: 1px solid;
    border-right: 1px solid;
    border-bottom: 1px solid;
    border-color: #dfe1e2;
    padding: 12px 20px 30px;

    p, span {
      color: $blackBackground;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 300;
    color: #7d868c;
  }

  &__text {
    color: $blackBackground;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;

    a {
      color: $globalColorLink;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    }
  }

  .button__promotion {
    color: #fff;
    background-color: $globalColorLink;

    @include hover {
      filter: brightness(1.1);
    }
  }

  &__card-link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.promotion-card__full-description {
  img {
    object-fit: contain;
    height: inherit;
  }
}