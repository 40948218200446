.page-wrapper {
  .page__services-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    &.page-container {
      padding: 40px 16px;
    }
  }
}

.page__services__list {
  &.spares__card-list {
    justify-content: flex-start;
  }

  .spares__card-list__item {
    margin-right: 1.5%;
    margin-bottom: 3%;
    padding: 20px 15px 0;
    height: 100px;
  }

  .spares__card-list__item__category-name {
    margin: 0;
    padding: 5px 0;
  }
}

.black-theme {
  position: relative;

  &::before {
    content: '';
    background-color: $blackBackground;
    z-index: -3;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .spares__card-list__item {
    .spares__card-list__item__category-name {
      color: #dee4e9;
    }
    @include hover {
      .spares__card-list__item__icon-category {
        background-color: #2f333d;
      }
    }
  }

  .spares__card-list__item__icon-category {
    background-color: $blackBackground; 
    box-shadow: 2px 0 5px rgba(0,0,0,.25);
  }
}

.feature-item {
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(51,51,51,.1);
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 20px;

  i {
    color: $globalColorLink;
    font-size: 30px;
    margin-right: 10px;
  }

  p {
    font-size: 11px;
    line-height: 13px;
    color: #a8a8a8;

    b {
      font-family: 'Muller';
      font-size: 16px;
      line-height:16px;
      font-weight: 600;
      color: $blackBackground;
      margin-top: 6px;
    }
  }
}

.price-list {
  width: 100%;

  .hiddenTable {
    overflow: hidden;
  }

  table {
    width: 100%;
    background-color: #fff;
    border-collapse: collapse;
    font-family: 'Muller';
    
    th {
      text-align: left;
      padding: 17px 25px;
      letter-spacing: 0;
      border: 1px solid #dee0e2;
      font-weight: 400;
      vertical-align: middle;
    }

    td {
      text-align: center;
      
      font-size: 14px;
      color: #a8a8a8;
      border: 1px solid #dee0e2;
      
      span {
        color: $blackBackground;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  .showMore.hidden {
    display: none;
  }

  .table-footer {
    background: rgba(222,224,226,.5);
    font-family: 'Muller';
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: .05em;
    color: rgba(43,46,55,.6);
    border: 1px solid #dee0e2;
    border-top: 0;
    padding: 3px 5px;
  }
}

.priceInfo {
  padding: 15px 25px;
  box-shadow: 5px 5px 20px rgba(51, 51, 51, 0.1);
  color: #fff;
  position: relative;

  &-title {
    font-family: 'Muller';
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .05em;
    text-transform: uppercase;
    margin-top: 0;
    color: #fff;
  }

  &-text {
    font-size: 14px;
    line-height: 20px;
    opacity: .8;
    border-top: 1px solid rgba(255,255,255, .8);
    padding-top: 13px;
    margin-top: 0;
    color: #fff;
  }

  .button {
    display: table;
    background-color: #fff;
    color: $globalColorLink;
    vertical-align: middle;
    line-height: initial;
    padding: 14px 16px;
  }

  &.hidden-button {
    .button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent;
      background-color: transparent;
      max-width: initial;
    }
  }

  &.hidden-all {
    display: flex;
    align-items: center;
    justify-content: center;
  
    .priceInfo-text {
      display: none;
    }

    .button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent;
      background-color: transparent;
      max-width: initial;
    }
  }
}

.showMore {
  display: block;
  width: max-content;
  text-align: center;
  margin: 25px auto;
  color: $globalColorLink;
  border-bottom: 1px dashed;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: 'Muller';

  span {
    color: $globalColorLink;
  }

}

.priceInfo {
  background-color: $globalColorLink;
  background-image: url('../img/priceInfo.png');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: 40%;
}

@include w768 {
  .price-list {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    .price-table {
      width: 65%;
    }
  }

  .priceInfo {
    width: 30%;
  }
}