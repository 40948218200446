.contacts {
  .footer-wrapper {
    margin-top: 0;
  }
}

.contacts-page {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  &__contacts-wrapper {
    padding-left: 35px;
    width: 24%;
  }

  .contacts__title {
    text-transform: uppercase;
    font-size: 12px;
    font-family: 'Muller';
    letter-spacing: .35px;
    margin-bottom: 12px;
    color: $blackBackground;
    font-weight: 400;

    &.contacts__icon {
      &:before {
        top: -7px;
      }
    }
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    address {
      font-style: normal;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: .45px;
    }

    a {
      color: $globalColorLink;
      font-size: 18px;
      line-height: 26px;
      font-family: 'Muller';
      font-weight: 300;
    }
  }

  .form {
    

    .form__checkbox {
      order: 1;
      width: 100%;
      margin: 10px 0;
    }

    .button {
      order: 2;
      padding: 0 25px;
    }
  }
}

