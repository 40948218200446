@mixin w600 {
  @media (min-width: 600px) {
    @content
  }
};

@mixin w768 {
  @media (min-width: 768px) {
    @content
  }
};

@mixin desktopContainer {
  max-width: 1152px;
  padding: 0 16px;
  margin: 0 auto;
};

@mixin mobileContainer {
  max-width: 1100px;
  padding: 0 16px;
  margin: auto;
}

@mixin hover {
  &:hover, &:focus { @content }
};

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}  
};