@import '../../node_modules/normalize.scss/normalize.scss';
@import './variables';
@import './mixins';
@import './fonts';
@import './main/popup_region';
@import './main/formPopup';
@import './main/burger';
@import './main/button';
@import './main/icons';
@import './main/footer';
@import './main/breadcrumbs';
// pages
@import './main/pages/mainPage';
@import './main/pages/promotionsList';
@import './main/pages/contacts';
@import './main/pages/serviceMaintenance';
@import './main/pages/carCovers';
@import './main/pages/restorationPage';
@import './main/pages/sparePage';
@import './main/pages/technicsPage';
@import './main/pages/gydrofications';
* {
  box-sizing: border-box;
  &:hover, &:focus {
    outline: none;
  }
}

body {
  overflow-x: hidden;
  margin: 0 auto;
  font-family: 'Fira Sans Extra Condensed', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

button, input[type="submit"] {
  -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

h1, h2, h3,p, span {
  color: $blackBackground;
}

a {
  text-decoration: none;
}

img{
  max-width: 100%;
  max-height: 100%;
}

ul {
  padding: 0;
  padding-left: 20px;
}

.slider__title {
  color: #fff;
}

.popup-background {
  background-color: rgba(0,0,0, .6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
  opacity: 0;
  width: 184%;
  height: 100%;
  overflow: hidden;

  &.active {
    z-index: 10;
    opacity: 1;
  }
}

.main-wrapper {
  position: relative;
}

.section__title {
  font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: 'Muller';
  letter-spacing: .07em;
  color: $blackBackground;

  &-green {
    color: $globalColorLink;
    font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: 'Muller';
  letter-spacing: .07em;
  }

  &-white {
    color: #fff;
    font-weight: 300;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  font-family: 'Muller';
  letter-spacing: .07em;
  }

  // @include w600 {
  //   font-size: 32px;
  //   line-height: 40px;
  // }
}

.align-center {
  text-align: center;
}

article {
  font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $blackBackground;
  a {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: $globalColorLink;
  }

  h2, h3, h4 {
    color: $globalColorLink;
    font-family: 'Muller';
    font-weight: 300;
    margin: 35px 0 10px;

    @include w600 {
      font-size: 32px;
      line-height: 40px;
    }
  }
  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 12px;
      font-size: 16px;
      line-height: 22px;

      &:before {
        content: '';
        position: absolute;
        top: 49%;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $globalColorLink;
      }
    }
  }
}

.text-container {
  letter-spacing: .45px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: $blackBackground;


  a {
    color: $globalColorLink;
    text-decoration: underline;
    text-decoration-color: $globalColorLink;
    transition: $transition;

    @include hover {
      text-decoration-color: transparent;
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 12px;
      font-size: 16px;
      line-height: 22px;

      &:before {
        content: '';
        position: absolute;
        top: 49%;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $globalColorLink;
      }
    }
  }

  h2, h3, h4 {
    color: $globalColorLink;
    font-family: 'Muller';
    font-weight: 300;
    margin: 35px 0 10px;

    @include w600 {
      font-size: 32px;
      line-height: 40px;
    }
  }
}

.section__text {
  letter-spacing: .45px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: $blackBackground;

  a {
    color: $globalColorLink;
    text-decoration: underline;
    text-decoration-color: $globalColorLink;
    transition: $transition;

    @include hover {
      text-decoration-color: transparent;
    }
  }
}

.section__link {
  color: $globalColorLink;
  text-decoration: underline;
}

.section__list {
  padding: 0;
  list-style: none;

  &-item {
    position: relative;
    margin-bottom: 10px;
    padding-left: 12px;
    font-size: 16px;
    line-height: 22px;
    color: $blackBackground;
    font-weight: 300;

    &:before {
      content: '';
      position: absolute;
      top: 49%;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: $globalColorLink;
    }
  }
}

.grey-background {
  position: relative;

  &::before {
    content: '';
    background-color: #f6f7f8;
    z-index: -3;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}

.color__white {
  color: #fff;
}

.color__green {
  color: $globalColorLink;
}

.service-title-block-wrapper {
  background-color: $blackBackground;
}

.page-title {
  font-size: 32px;
  font-weight: 300;
  line-height: 30px;
  font-family: 'Muller';
  text-transform: uppercase;
  letter-spacing: .07em;
  padding: 20px 0 0;
  margin: 0;

  &.margin {
    margin-top: 100px;
    margin-bottom: 39px;
  }
}

.page-wrapper .page-container {
  min-height: 28vh;
}

.page-description {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  letter-spacing: .9px;
  font-weight: 300;

  a {
    color: $globalColorLink;
    text-decoration: underline;
    text-decoration-color: $globalColorLink;
    transition: $transition;

    @include hover {
      text-decoration-color: transparent;
    }
  }

  .link {
    text-decoration: none;
    color:#ababab;

    @include hover {
      color: $globalColorLink;
    }
  }
}

.green-circle {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    right: 75px;
    top: 50px;
    width: 449px;
    height: 449px;
    border-radius: 50%;
    background-color: $globalColorLink;
  }
}

.page-text-space {
  font-weight: 300;
  letter-spacing: .45px;
}

.notFound {
  
  .service-title-block__image {
    display: block;
  }

  .service-title-block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;

    h1 {
      padding-bottom: 30px;
    }

    p {
      color: #7d868c;
    font-size: 20px;
    font-weight: 300;
    margin-top: 7px;
    letter-spacing: .5px;

    a {
      color: #7d868c;
      border-bottom: 1px solid rgba(125,134,140,.5);
      transition: $transition;

      @include hover {
        border-color: transparent;
      }
    }
    }
  }
  .page-title {
    font-weight: 300;
    font-size: 85px;
    font-family: Muller;
    letter-spacing: 5px;

    @media only screen and (max-width: 430px) {
      font-size: 60px;
    }
  }
}