.gydrofication__tech-types {
  padding: 40px 0;

  .page-title.margin {
    margin: 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
    padding: 0;

    &-item {
      background-color: #fff;
      box-shadow: 3px 4px 15px rgba(51, 51, 51, .1);
      text-align: center;
      margin-bottom: 15px;

      img {
        padding: 25px 0 0;
      }

      h4 {
        position: relative;
        padding: 15px 0;
        margin: 25px 0 0;
        text-transform: uppercase;
        font-family: Muller;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: .25px;
        color: $blackBackground;

        &::after {
          content: "";
          position: absolute;
          width: 90%;
          border-bottom: 1px solid #efefef;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.gydrofication__suppliers {
  padding: 40px 0 !important;
  background-color: $blackBackground;

  .page-title.margin {
    margin: 0 0 20px;
  }

  h2, p, h3 {
    color: #fff;
  }

  &__item {
    background-color: rgba(42,45,55,.75);
    -webkit-box-shadow: 3px 4px 70px rgba(0,0,0,.33);
    box-shadow: 3px 4px 70px rgba(0,0,0,.33);
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 28px;
    margin-bottom: 40px;

    &__content {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
        border-bottom: 1px solid rgba(255,255,255,.1);
      }
    }

    &__slider-wrapper {
      h3 {
        font-family: Muller;
        font-size: 18px;
        font-weight: 300;
        line-height: 26px;
        text-transform: uppercase;
        letter-spacing: .9px;
        margin-bottom: 28px;
        text-align: center;
      }
      .swiper-slide {
        height:320px;
        padding: 0;
        display: flex;
        img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: -1;
          height: 100%;
          object-fit: cover;
        }

        p {
          align-self: flex-end;
          text-align: center;
          width: 100%;
          background-color: rgba(0,0,0,.3);
          margin: 0;
          padding: 25px 0;
        }

        &::after {
          content: '';
          background-color: rgba(0,0,0,.3);
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: $transition;
        }

        @include hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }
  .gydrofication__suppliers__item__slider {
    height: fit-content;
    margin-top: 0;
  }
  
}

.restoration-services {
  .page-title.margin {
    margin-top: 80px;
  }
}